body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-image: url(/blizzard.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

*, *::before, *::after {
  box-sizing: inherit;
}

html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
}

html body {
  padding: 0;
  margin: 0;
  overflow-x: inherit;
}

.show-password {
  width: 103px;
  height: 18px;
  left: 69px;
  top: 517px;
  
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height, or 129% */
  
  letter-spacing: -0.266176px;
  
  color: "red";
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content {
  padding-bottom: 8.5em;
}

.card-header {
  background-color: #0e9ed7;
  color: white;
  font-weight: bold;
}

.btn-primary {
  background-color: #0e9ed7 !important;
}
.btn-secondary {
  background-color: white !important;
}

.nav-link {
  font-size: 22px;
  font-weight: 500;
  color: #FFFFFF !important;
}

.nav-link-new {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  letter-spacing: 0.16px;
  text-decoration-line: underline;

  color: #000000;
}

.nav-link:active {
  font-size: 22px;
  font-weight: 500;
  color: #0e9ed7;
}

input[type="checkbox"] {
  width: 25px;
  height: 25px;
  padding: 6px;
  margin-left: 15px;
  margin-right: 15px;
}

.footer-section {
  background-color: #e7e7e7;
  font-size: 16px;
  color: gray;
  padding-top:20px;
  bottom: 0;
  position: sticky;
  opacity: .8;
  padding-bottom: 20px;
  padding-left: 36em;
}

.footer-section a {
  color: #0e9ed7;
}

.footer-row {
  text-align: center;
  margin-left: 36em;
}

footer {
  background-color: #e7e7e7;
  font-size: 18px;
  color: #545454;
  padding-top:20px;
  padding-bottom:20px;
  opacity: .9;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.modal-75 {
  min-width: 75%;
}

.header-delete {
  margin-top: 20px;
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  font-family: "Helvetica Neue";
}

.text-delete {
  padding-left: 2px;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  font-family: "Helvetica Neue";
}
  
.column-delete-left {
  height: 50px;
}

.column-delete-right {
  height: 50px;
}

@media (max-width: 812px){
  
  .header-delete {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    font-family: "Helvetica Neue";
  }
  
  .text-delete {
    margin-left: -10px;
    margin-right: -10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: "Helvetica Neue";
  }
  
  .column-delete-left {
    margin-left: -30px;
    margin-right: -10px;
    height: 50px;
  }
  
  .column-delete-right {
    margin-left: -10px;
    margin-right: -30px;
    height: 50px;
  }

  footer {
    background-color: #e7e7e7;
    font-size: 18px;
    color: #545454;
    padding-top:20px;
    padding-left:20px;
    padding-bottom:20px;
    opacity: .9;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 200px;
  }

    .footer-section{
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
      margin-top:400px;
    }
    
    .footer-row {
      margin-left: 0;
    }
}

.card-bg {
  background-color: #FFFFFF;;
  padding: 15px;
  border-radius: 5px;
}

.card-new {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 50px;
}

.card-new-logged-in {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 15px;
}

.card-new-no-border {
  background: #FFFFFF;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.padding {
  padding: 10px;
}

.device-row-mobile {
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 20px;
}

.device-p-mobile-field {
  font-size: 16;
  font-family: "SF Pro Text";
  font-weight: 600;
}

.device-p-mobile-info {
  font-size: 16;
  font-family: "SF Pro Text";
  margin-left: 8;
}

.header-5 {
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  font-family: "Helvetica Neue";
}

.header-6 {
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  font-family: "Helvetica Neue";
}

.security {
  font-weight: 650;
  font-size: 20px;
  line-height: 30px;
  font-family: "Helvetica Neue";
}

.codes {
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  font-family: "SF Pro Display";
}

.otp-sub {
  margin-top: 15px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: "SF Pro Text";
}

.otp-modal-sub {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  font-family: "SF Pro Text";
}

.security-text {
  font-size: 15px;
  line-height: 30px;
  font-family: "Helvetica Neue";
}

.verified {
  font-size: 15px;
  line-height: 30px;
  font-weight: bold;
  font-family: "Helvetica Neue";
  color: #569E00;
}

.pending {
  font-size: 15px;
  line-height: 30px;
  font-weight: bold;
  font-family: "Helvetica Neue";
  color: #F87431;
}

.radio-text {
  padding-left: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  font-family: "Helvetica Neue";
}

.radio-text-current {
  padding-left: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  font-family: "Helvetica Neue";
}

.card-new-success {
  background-color: transparent;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 50px;
}

.card-warning {
  background-color: #EEEEEE;
  padding: 15px;
  border-radius: 5px 5px 0px 0px;
  opacity: .9;
}

.card-bg label {
  color: white;
}